import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject(error.response || 'Something went wrong')
);
// axiosInstance.interceptors.response.use(
//   (res) => res,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const getFetcher = async (url, config) => {
  const res = await axiosInstance.get(url, { ...config });
  return res.data;
};
export const getFetcher1 = async (url, config) => {
  const res = await axiosInstance.get(url, { ...config });
  return res;
};

export const getFetcherPrams = async (url, data, params, config) => {
  const res = await axiosInstance.get(url, data, { params, ...config });
  return res.data;
};

export const getFetcherPrams2 = async (url, data, params, config) => {
  const res = await axiosInstance.get(url, data, { params, ...config, responseType: 'blob' });
  return res;
};

export const postFetcher = async (url, data, config) => {
  const res = await axiosInstance.post(url, data, { ...config });
  return res.data;
};

export const putFetcher = async (url, data, config) => {
  const res = await axiosInstance.put(url, data, { ...config });
  return res.data;
};

export const deleteFetcher = async (url, config) => {
  const res = await axiosInstance.delete(url, { ...config });
  return res.data;
};

export const postPramsFetcher = async (url, data, params, config) => {
  const res = await axiosInstance.post(url, data, { params, ...config });
  return res.data;
};

export const postPramsFetcherNew = async (url, data, params, config) => {
  const res = await axiosInstance.post(url, data, { params, ...config });
  return { status: res.status, data: res.data };
};

export const postPramsFetcher1 = async (url, data, params, config) => {
  const res = await axiosInstance.post(url, data, { params, ...config });
  return res;
};
export const postPramsFetcher2 = async (url, data, params, config) => {
  const res = await axiosInstance.post(url, data, { params, ...config, responseType: 'blob' });
  return res;
};

export const postFetcher1 = async (url, data, config) => {
  const res = await axiosInstance.post(url, data, { ...config });
  return res;
};
export const postFetcher2 = async (url, data, config) => {
  const res = await axiosInstance.post(url, data, { ...config });
  return res.data;
};


// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/auth/signin',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  supplier: {
    add: '/v1/suppliers/supplier',
    getAllSuppliers: '/v1/suppliers/supplier',
    getSupplierById: '/v1/suppliers/supplier',
    getSupplierAddressById: '/v1/suppliers-address/addresses/address-search',
    poSearchbySupplierId: '/v1/purchase/order-search-id',
    PoSearchBySupplierIdAndPoNumberStatus5: '/v1/purchase/order-search-id-active',
    PoSearchBySupplierIdAndPoNumberStatusWise: '/v1/purchase/order-search-id',
    supplierUpdate: '/v1/suppliers/supplier',
    supplierReport: '/v1/suppliers/report',
  },
  materialMaster: {
    createMaterialMaster: '​/v1​/items​/all',
    searchMaster: '/v1/items/item-search',
    materialMasterList: '/v1/items/all',
    materialMasterGetbyId: '/v1/items',
    materialReport: '/v1/items/report',
    materialReportDateFilter: '/v1/items/report',
    priceUpdateItemWise: '/v1/items/price-update-item',
    categoryWiseSearch: '/v1/items/category-wise-search',
    priceHistoryList: '/v1/price-history'
  },
  systemSettings: {
    productFeildList: '/v1/material-master/fields',
    systemMasterList: '/v1/items/all/0/10',
    systemMasterSearchList: '/v1/items/item-search',
    defineFiscalyear: '/v1/fiscal-year',
    getAllFiscalyear: '/v1/fiscal-year',
    definePeriod: '/v1/period-def',
    getAllPeriod: '/v1/period-def',
    getbyid: '/v1/fiscal-year',
    getbyid1: '/v1/period-def',
    updateFiscalyear: '/v1/fiscal-year',
    updatePeriodDefinition: '/v1/period-def',
    getSystemSettingFiscalyear: '/v1/system_settings/fiscal-year',
    addFiscalyeartoSystemSetting: '/v1/system_settings/fiscal-year',
    getAllFiscalYearPeriod: '/v1/period-def/fiscal-year-period'
  },
  systemMaster: {
    create: '/v1/items/item',
    update: '/v1/items/item',
    valuesCreate: '/v1/item-values/values',
    valuesEdit: '/v1/item-values/values',
  },
  purchaseOrder: {
    createPurchaseOrder: '/v1/purchase/order',
    getAllPurchaseOrder: '/v1/purchase/order',
    getById: '/v1/purchase/order-id',
    poSearchByPodateRange: '/v1/purchase/order-date-filter',
    poReport: '/v1/purchase/report',
    approve: '/v1/purchase/approve',
  },
  goodReceiptnote: {
    createGoodReceiptNote: '/v1/good-receipts/receipt',
    grnSearchBySupplierId: '/v1/good-receipts/receipt',
    getAllGoodReceiptNote: '/v1/good-receipts/receipt',
    getById: '/v1/good-receipts/receipt',
    goodReceptDateFilter: '/v1/good-receipts/date-filter',
    goodReceptDataReport: '/v1/good-receipts/report',
    getCurrencyById: 'v1/currency/get-by-id',
  },
  companyAddressController: {
    createCompanyAddress: '/v1/company-address/address',
    companyAddress: '/v1/company-address/address',
  },
  invoice: {
    createInvoice: '/v1/invoices/invoice',
    getAllInvoice: '/v1/invoices/invoice',
    getById: 'v1/invoices/invoice',
    invoiceFilterbyDate: 'v1/invoices/date-filter',
    invoiceReport: 'v1/invoices/receipt',
    invoiceSearch: 'v1/invoices/invoice'
  },
  paymentType: {
    createPaymentType: '/v1/payments',
    getAllPaymentType: '/v1/payments/payment',
    createPaymentTerms: '/v1/payment-terms',
    getPaymentTerms: '/v1/payment-terms',
  },
  incoterms: {
    createIncoterms: '/v1/incoterms',
    getAllIncoterms: '/v1/incoterms',
  },
  deliveryCondition: {
    createDeliveryCondition: '/v1/delivery-conditions',
    getAlldeliveryCondition: '/v1/delivery-conditions',
  },
  deliveryTerms: {
    createDeliveryTerms: '/v1/delivery-terms',
    getAlldeliveryTerms: '/v1/delivery-terms',
  },
  priceManagement: {
    createPricing: '/v1/price',
    getAllPricing: '/v1/price',
  },
  systemUser: {
    createAdminUser: '/auth/signup',
    getSystemUser: '/v1/user',
    getUserRole: '/v1/user/roles',
    addLocation: '/v1/user/location',
    getUserbyid: '/v1/user',
    searchSystemUser: '/v1/hr-user-fetch/search-erp'
  },
  customerCreation: {
    createCustomer: '/v1/customers',
    searchCustomerByMobile: '/v1/customers/search',
  },
  createLocation: {
    createBranchLocation: '/v1/location',
    getAllLocation: '/v1/location',
    getLocationById: '/v1/location',
    addUserLocation: '/v1/user/location',
  },
  pos: {
    checkInCheckOut: 'v1/pos-check-in',
    searchByBarCode: '/v1/pos/barcode',
    SearchByItemId: '/v1/pos/id',
    posCheckout: '/v1/pos/checkout',
    posCompleted: '/v1/pos/completed',
    mobileFilter: 'v1/pos/completedByMobile',
    addCart: '/v1/cart',
    getCart: '/v1/cart',
    getByUser: '/v1/cart/user',
    deleteCart: '/v1/cart',
    stockBatchwise: '/v1/batch',
    voidCart: '/v1/cart',
    posHistory: '/v1/pos/sales',
    posHistoryall: '/v1/pos/sales-all',
    GetPurchaseHistoryAllFilterMobile: '/v1/pos/sales-all-mobile',
    getPurchaseHistoryInvoiceSearch: '/v1/pos/sales-all-mobile-with-invoice',
    GetPurchaseDiscountAllFilterMobile: '/v1/pos/sales-all-mobile-discount',
    posReturnbill: '/v1/pos/bill',
    posReturnbillall: '/v1/pos-return/all',
    posBillReyurnHistory: '/v1/pos-return',
    itemSearchByname: '/v1/pos/name',
    SearchByNameReturnBarcode: '/v1/pos/item-name',
    SearchByBillId: '/v1/pos/search-by-bill-id',
    // invoice load with stiching and without stiching
    SearchByBillIdPendingMeasurement: '/v1/pos/pending-measurement/search-by-bill-id',
    SearchByBillIdPendingMeasurementNew: '/v1/pos/pending-measurement-lines-without-stitching-charges/search-by-bill-id',
    GetReportBill: "/v1/pos/report",
    posDiscountHistory: 'v1/pos/discount-report',
    getReceivableByMobile: 'v1/pos/pendingByMobile',
    getAllReceable: "/v1/pos/pending",
    getBillbyid: "/v1/pos/bill",
    posPaymentSettlement: '/v1/pos-payment',
    completedByInvoice: 'v1/pos/completedByInvoice',
    posReceivable: 'v1/pos/receivable/report',
    posReceived: 'v1/pos/received/report'
  },
  posPayment: {
    getCollectionData: 'v1/pos-payment/report',
    getCollectionDataRange: 'v1/pos-payment/report-date-range',
    getCollectionDataSummery: 'v1/pos-payment/summery',
  },
  report: {
    getHeaderList: '/v1/stock/header',
    getBatchList: '/v1/stock/batch',
    ItemWiseMovement: '/v1/stock/item-wise-movement',
    getDetailsStockReport: '/v1/stock/stock-date-wise',
  },
  salesOrder: {
    createSalesOrder: '/v1/so',
    getAllSalesOrder: '/v1/so',
    getByid: '/v1/so',
    changePickingStatus: '/v1/so/packing',
    getAllPickingList: '/v1/so',
    changeDeliveryStatus: '/v1/so/delivery',
    getStatuswiseSalesOrder: 'v1/so/status-wise',
    getSalesOrderalltheStatus: 'v1/so/status',
    accountReceivable: 'v1/so/account-receivable',
    accountPayble: 'v1/payable',
    accountPayblePending: 'v1/payable/pending',
    customerOutstanding: 'v1/so/customer/balance',
  },
  price: {
    createPrice: '/v1/price',
    getAllPrice: '/v1/price',
    priceListCreate: '/v1/prices',
    getAvailableAndInitialQty: '/v1/batch',
    getAllPriceList: '/v1/prices',
    priceAssign: '/v1/price-assign',
    priceAssignList: '/v1/price-assign',
    soPriceListAssign: '/v1/price-assign/so-item-search',
    priceAssignSearchBy: '/v1/prices/search-by-Item',
  },
  outBoundDelivery: {
    getAllPendingOutBound: '/v1/so',
  },
  paymennt: {
    getAllPayment: '/v1/payment',
    paymentSettle: '/v1/payment-line',
    postDatedChequeDetails: '/v1/payment-line/pending-cheque',
    chequeStatusChange: '/v1/payment-line/payment-status-change',
    getPaymentByid: '/v1/payable-line',
    supplierPaybleLine: '/v1/payable-line',
    getPaymentmethodbyid: 'v1/payable-line/method',
    paybleLineStatus: 'v1/payable-line/status',
  },
  defineCreditLimit: {
    createCreditLimit: '/v1/credit-limit',
    getAllCreditLimit: 'v1/credit-limit',
  },
  internalTransfer: {
    createInternalTransfer: '/v1/internal-transfer',
    transedStockList: '/v1/internal-transfer/send',
    stockListBatchwise: '/v1/batch',
    getByid: '/v1/internal-transfer/view',
    approveInternal: '/v1/internal-transfer',
    stockTransferGetbyiid: '/v2/internal-transfer',
    stockReportBranchwise: '/v1/batch',
  },
  internalTransferv2: {
    Add: "/v2/internal-transfer",
    SearchWithoutBranch: "/v2/internal-transfer/search",
    SearchWithBranch: "/v2/internal-transfer/search-branch-wise",
    GetById: "/v2/internal-transfer",
    Approve: "/v2/internal-transfer/approve",
    stockTransferExcel: "/v2/internal-transfer/date-rang-filter",
  },
  stockAdjutment: {
    Add: "/v1/stock-adjustment",
    Adjustmentrestore: '/v1/adjustment-restore',
    GetStockAdjustment: "/v1/stock-adjustment",
    StockAdjustmentReport: "/v1/stock-adjustment/report",
    stockAdjustmentGetbyid: '/v1/stock-adjustment',
    approved: "/v1/stock-adjustment/approve",
  },
  additionalStock: {
    getAdditionalStockType: '/v1/Additional-Stock-Type',
    AddAdditionalStockType: '/v1/Additional-Stock-Type',
  },
  location: {
    locationgetByid: '/v1/location',
  },
  cashDetails: {
    cashPayable: '/v1/payable-line/method',
    cashReceiable: '/v1/payment-line',
  },
  category: {
    categoryCreate: '/v1/category',
    categoryLevelSearch: '/v1/category/search-by-category',
    categoryLevel3Search: '/v1/category/search-category-level3',
    categoryLevel2Search: '/v1/category/find-category-level1',
  },
  finance: {
    main: '/v1/finance/main',
    subCategory1: '/v1/finance/subCategory1',
    subCategory2: '/v1/finance/subCategory2',
    subCategory3: '/v1/finance/subCategory3',
    subCategory4: '/v1/finance/subCategory4',
    getChartofaccounts: '/v1/finance/view',
    accountType: '/v1/finance-line',
    accountTypeList: '/v1/finance-line',
    accountTypeListJournal: 'journal/v1/finance-line',
    // accounts
    findAccounts: '/v1/finance-line/find',
    findAccountsLeveltwo: '/v1/finance-line/find',
    findExpenses: '/v1/finance-line/expenses',
    cashTransaction: '/v1/cash-bank',
    expenseTransaction: '/v1/cash-bank/expenses',
    transactionCredit: '/v1/bill',
    transactionCreditExpenses: '/v1/bill/expenses',
    createVat: 'v1/vat',
    getVat: 'v1/vat',
    getallVat: 'v1/vat',
    searchAllAcc: 'v1/finance-line',
    SearchPettyCash: 'v1/finance-line/petty-cash',
    SearchAll: 'v1/finance-line/search-all',
    searchAlldepartment: 'v1/hr-department-fetch',
    searchAllemployee: 'v1/hr-user-fetch/search',
    pettyCashPaymentList: 'v1/cash-bank/view',
    paymentGetbyid: 'v1/cash-bank/view',
    pettyCashReport: 'v1/cash-bank/cash-bank',
    pettyCashRegisterReport: 'v1/cash-bank/petty-cash-register',
    pettyCashDateFilter: 'v1/cash-bank/report',
    genaralLedger: 'v1/cash-bank/petty-cash-journal',
    pettyCashFloat: 'v1/petty-cash-definition',
    pettyCashFloatList: 'v1/petty-cash-definition',
    journalview: 'v1/account-transaction',
    journalviewwithPagination: '/api/v1/account-transaction/with-pagination',
    getViewSearch: 'v1/cash-bank/view-search',
    journalViewFilter: 'v1/account-transaction/with-pagination',
    journalViewFilterExcelExport: 'v1/account-transaction/journal-excel',
    cashRecept: 'v1/payment-receipt',
    cashReceiptList: 'v1/payment-receipt',
    SearchByDate: 'v1/payment-receipt/search-by-date',
    cashReceiptSearch: 'v1/payment-receipt/search',
    cashgetByid: 'v1/payment-receipt',
    cashReceiptReport: 'v1/payment-receipt/generate-report',
    journalAccSearch: 'v1/finance-line/search',
    getReportForDetails: 'v1/finance-line/report',

    cashAndBankReceiptCommon: 'v1/common-receipt',
    cashAndBankReceiptGetbyid: 'v1/common-receipt',
    cashAndBankReceiptGetAll: 'v1/common-receipt',

    itemGroupWiseSalesReport: 'v1/report/sales-group',
    itemGroupWiseSalesReportView: 'v1/report/sales-group-view',
    // wrong api address for sales report
    itemGroupWiseSalesReportViewNew: 'v1/report/item-group-wise-stock-view',

    customerWiseSalesReport: 'v1/report/customer-wise-sales-report',
    customerWiseSalesReportView: 'v1/report/customer-wise-sales-report-view',

    itemWiseSalesReport: 'v1/report/item-wise-sales-report',
    // withoutPagination
    itemWiseSalesReportView: 'v1/report/item-wise-sales-report-view',
    itemWiseSalesReportViewNew: 'v1/report/item-wise-sales-report-view',

    locationWiseStockReport: 'v1/report/location-wise-stock-report',
    locationWiseStockReportView: 'v1/report/location-wise-stock-report-View',

    itemGroupwiseStockReport: 'v1/report/item-group-wise-stock-report',
    // item group wise stock report
    itemGroupwiseStockReportView: 'v1/report/item-group-wise-stock-view',
    itemGroupwiseStockReportViewNew: 'v1/report/item-group-wise-stock-branch-view',

    // locationwiseDetailsStockReport: 'v1/report/stock',
    locationwiseDetailsStockReport: 'v1/report/location-wise-detail-stock-report',
    locationwiseDetailsStockReportView: 'v1/report/location-wise-detail-stock-report-view',

    detailsStockReport: 'v1/report/detailed-stock-report-with-value',
    detailsStockReportView: 'v1/report/detailed-stock-report-with-value-view',

    dailyCollectionReport: 'v1/report/daily-collection',
    dailyCollectionReportView: 'v1/report/daily-collection-view',
    getTrialBalance: 'v1/account-transaction/trail-balance',
    getTrialBalanceView: 'v1/account-transaction/trail-balance-view',




    salesSummeryReport: 'v1/report/sales-summery-branch-wise',
    salesSummeryReportView: 'v1/report/sales-summery-branch-wise-view',

    posReport: 'v1/pos/report',

    locationwiseDetailsSalesSummary: 'v1/report/location-wise-detail-sales-report_summery',
    locationwiseDetailsSalesSummaryNew: 'v1/report/location-wise-detail-sales-report_summery-new',
    locationwiseDetailsStockSummary: 'v1/report/location-wise-detail-stock-report',
    // Location wise Details Sales Summary View 
    locationwiseDetailsSalesSummaryView: 'v1/report/location-wise-detail-sales-report_summery-view',

    finConfig: 'v1/acc-group-report-type',
    finConfigUpdate: 'v1/acc-group-report-type',
    finConfigList: 'v1/acc-group-report-type',
    finConfigbyId: 'v1/acc-group-report-type',
    // finConfigUpdate: 'v1/acc-group',

    setpnltoSystemSetting: 'v1/system_settings/set-pnl',
    setBStoSystemSetting: 'v1/system_settings/set-balance-sheet-group-type',

    addAccGroup: 'v1/acc-group',
    getAllGroup: 'v1/acc-group',
    finGroupConfigbyId: 'v1/acc-group',
    finGroupConfigUpdate: 'v1/acc-group',
    finGroupLineUpdate: 'v1/acc-group/line-update',
    finGroupOparation: 'v1/acc-group/operations',
    lineGetbyid: 'v1/acc-group/line',
    lineGetAll: 'v1/acc-group/line-list',

    journalEntry: 'v1/journal-manual-entry',
    journalEntryList: 'v1/journal-manual-entry',

    genaratePnl: 'v1/acc-pnl',
    genarateBS: 'v1/acc-balance-sheet'
  },
  measurements: {
    lengthTypes: 'v1/pos-len-style',
    nTypes: 'v1/pos-n',
    shTypes: 'v1/pos-sh',
    slTypes: 'v1/pos-sl',
    swTypes: 'v1/pos-sw',
    wTypes: 'v1/pos-w',
    fpTypes: 'v1/pos-fp',
    dwTypes: 'v1/pos-dw',
    kallyTypes: 'v1/pos-kally',
    styles: 'v1/pos-style',
    fit: 'v1/pos-fit',
    relations: 'v1/pos-relation',
    pocketTypes: 'v1/pos-pocket',
    dfTypes: 'v1/pos-df-types',
    sSeamTypes: 'v1/pos-sseam',
    buttonTypes: 'v1/pos-button',
    loop: 'v1/pos-loop',
    fpOepnTypes: 'v1/pos-w',
    pTypes: 'v1/pos-w',
    addNewData: "v1/measurement",
    updateData: 'v1/measurement',
    // getAllMeasurements: "v1/measurement",
    getAllMeasurements: "v1/measurement",
    getMeasurementId: "v1/measurement",
    getMeasurementOriginalId: "v1/measurement/original",
    getMeasurementOriginalEdit: "v1/measurement/original-edit",
    getMeasurement: "v1/measurement",
    getMeasurementForTrail: "v1/measurement/stock-issue",
    measurementWith: "v1/measurement/filtered_measurement",
    shCutTypes: "v1/pos-sh-cut",
    fundaLength: "v1/pos-funda",
    fundaTypes: "v1/fund-type",
    darizBack: "v1/dariz-back",
    darizFront: "v1/dariz-front",
    darizHalf: "v1/dariz-half",
    darizNeck: "v1/dariz-neck",
    darizNeckStyle: "v1/dariz-neck-style",
    darizSleeve: "v1/dariz-sleeve",
    darizType: "v1/dariz-type",
    darizDesignType: "v1/dariz-design-type",
    measurementLengthUpdate: 'v1/measurement/length-update',
    measurmentPrint: "v1/measurement/print",
    measuredBy: "v1/hr-user-fetch/measurement-user-search",
    getPossBill: "v1/pos/bill",
    stockIssuringPrint: 'v1/measurement/print-new',

    trialConfirmationList: 'v1/measurement/measurement-confirm-list',
    newtrialConfirmationList: 'v1/measurement/measurement-confirm-list',
    trialConfirm: 'v1/measurement/measurement-confirm',

    stockIssuingList: 'v1/measurement/stock-issue',

    searchBranchUser: '/v1/hr-user-fetch/branch-wise',

    measurementPrintCount: 'v1/measurement/print'
  },
  manufacturing: {
    capacity: 'v1/manufacturer-capacity/get',
    capacityAdd: 'v1/manufacturer-capacity',
    manufacturingConfig: 'v1/measurement-config',
    manufacturingCapacityCheck: 'v1/manufacturer-capacity/check'
  },
  unitOfMeasurement: {
    createUom: 'v1/unit-of-measurement',
    getUom: 'v1/unit-of-measurement'
  },
  costCenters: {
    createCC: 'v1/cost-center',
    getCostCenter: 'v1/cost-center',
    costCenterSearch: 'v1/cost-center'
  },
  subAccounts: {
    createSubAcc: 'v1/sub-account-category',
    subAccountCategoryAll: 'v1/sub-account-category',
    subAccAllwithPagination: 'v1/sub-account-category',
    subAccountsSearch: 'v1/sub-account/search'
    // getCostCenter: 'v1/cost-center',
    // costCenterSearch: 'v1/cost-center'
  },
  subAcc: {
    SubAcc: 'v1/sub-account',
    subAccAll: 'v1/sub-account',
    subAccSearch: 'v1/sub-account/search'
    // getCostCenter: 'v1/cost-center',
    // costCenterSearch: 'v1/cost-center'
  },
  honorific: {
    createHonorific: 'v1/person-definition',
    getHonorific: 'v1/person-definition/enable'
  },
  cashRegisterOpenAndClose: {
    getRegisterStatus: 'v1/cash-register',
    getRegisterOpen: 'v1/cash-register'
  },
  stockAjustment: {
    addStockAdjustment: 'v1/stock-adjusment'
  },
  measurementMaster: {
    createDesignNo: 'v1/pos-design-no',
    createRelation: 'v1/pos-relation',
    getRelation: 'v1/pos-relation',
    createFit: 'v1/pos-fit',
    getFit: 'v1/pos-fit',
    createSSEAM: 'v1/pos-sseam',
    getSSEAM: 'v1/pos-sseam',
    CreateFUNDALength: 'v1/pos-funda',
    getFUNDALength: 'v1/pos-funda',
    CreateBUTNType: 'v1/pos-button',
    getBUTNType: 'v1/pos-button',
    CreateLOOP: 'v1/pos-loop',
    getLOOP: 'v1/pos-loop',
    CreateBack: 'v1/dariz-back',
    getBack: 'v1/dariz-back',
    CreateNeckStyle: 'v1/dariz-neck-style',
    getNeckStyle: 'v1/dariz-neck-style',
    CreateHalf: 'v1/dariz-half',
    getHalf: 'v1/dariz-half',
    designNumberSearch: 'v1/pos-design-no/search',
    getDesignNumber: 'v1/pos-design-no',
    createStyle: 'v1/pos-style',
    getStyle: 'v1/pos-style',
    createShCut: "v1/pos-sh-cut",
    getShCut: 'v1/pos-sh-cut',
    createPocketType: 'v1/pos-pocket',
    getPocketType: 'v1/pos-pocket',
    createFUNDA: 'v1/pos-funda',
    createFundaType: 'v1/fund-type',
    getFundaType: 'v1/fund-type',
    getFUNDA: 'v1/pos-funda',
    createDFType: 'v1/pos-df-types',
    getDFType: 'v1/pos-df-types',
    createSHType: 'v1/pos-sh',
    getSHType: 'v1/pos-sh',
    createKALLY: "v1/pos-kally",
    getKALLY: 'v1/pos-kally',
    createDarizFront: 'v1/dariz-front',
    getDarizFront: 'v1/dariz-front',
    createDarizNeck: 'v1/dariz-neck',
    getDarizNeck: 'v1/dariz-neck',
    createDarizSleeve: 'v1/dariz-sleeve',
    getDarizSleeve: 'v1/dariz-sleeve',
    createDarizType: 'v1/dariz-type',
    getDarizType: 'v1/dariz-type',
    measurementReport: 'v1/measurement/report',
    measurementStockIssuedReport: 'v1/measurement/stock-issued-report',
    measurementTrialConfirmedReport: 'v1/measurement/trial-confirm-report',
  },
  currencyManagement: {
    addCurrency: 'v1/currency',
    updateCurrency: 'v1/currency',
    getAllCurrency: 'v1/currency',
    getConfigureCurrency: 'v1/currency',
    currencyGetByid: 'v1/currency/get-by-id',
    getSystemCurrency: 'v1/system_settings/currency',
    setSystemCurrency: 'v1/system_settings/currency',
    currencyRateGetbyid: 'v1/currency/get-by-id'
  },
  costUpdate: {
    addCostupdate: 'v1/cost-update',
    getAllCostupdate: 'v1/cost-update',
    getbyidCostupdate: 'v1/cost-update',
    updateCostupdate: 'v1/cost-update/update',
    costUpdateConfigCh: 'v1/system_settings/set-cost-update-start-character',
    costUpdateConfigNum: 'v1/system_settings/set-cost-update-start-number',
    getcostUpdateConfigCh: 'v1/system_settings/get-cost-update-start-character',
    getcostUpdateConfigNum: 'v1/system_settings/get-cost-update-start-number',
    costUpdateFinalized: 'v1/cost-update/finalized',
    costUpdatefinalizedByid: 'v1/cost-update/finalized',
    updateCostupdateLineList: 'v1/cost-update-line',
    finalCostUpdate: 'v1/cost-calculation'
  },
  reference: {
    addReference: 'v1/reference-status/add',
  },
  corporateDiscount: {
    createCorporates: 'v1/corporate',
    getAllCorporates: 'v1/corporate',
    corporatesGetbyid: 'v1/corporate',
    updateCorporates: 'v1/corporate',
    getAllValidCorporates: 'v1/corporate/valid',

    addCorporateDiscount: 'v1/cooperate_discount',
    getAllCorporateDiscount: 'v1/cooperate_discount',

    corporateDiscountLine: 'v1/corporate-discount-line',
    corporateGetbyid: 'v1/corporate',

    addCorporateDiscountLine: 'v1/corporate-discount-line',

    getCorporatesItem: 'v1/corporate',
    getCorporatesItemRate: 'v1/corporate/rate',
  }

};
