import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { SystemListView, SystemProfileView } from 'src/sections/systemSettings/view';
import OverviewPOSView from 'src/sections/pos-overview/dashboard/view/overview-pos-view';

// import FitCreatePage from 'src/pages/dashboard/measurementMaster/createFit';
import { element } from 'prop-types';
// import CurrencyManagementConfigurePage from 'src/pages/dashboard/currencyManagement/configure';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// MULTIPLE STOCK TRANSFER
const MultipleStockTransferListPage = lazy(() => import('src/pages/dashboard/multipleStockTrans/list'));
const MultipleStockTransferDetailsPage = lazy(() => import('src/pages/dashboard/multipleStockTrans/details'));
const MultipleStockTransferAcceptPage = lazy(() => import('src/pages/dashboard/multipleStockAccept/details'));
const MultipleStockTransferCreatePage = lazy(() => import('src/pages/dashboard/multipleStockTrans/new'));
const MultipleStockTransferEditPage = lazy(() => import('src/pages/dashboard/multipleStockTrans/edit'));
// REPORT
const BalanceListPage = lazy(() => import('src/pages/dashboard/report/list'));
const ProfitandLosstListPage = lazy(() => import('src/pages/dashboard/pnl/list'));
const ProfitandLosstCreatePage = lazy(() => import('src/pages/dashboard/pnl/new'));

const ReportDetailsPage = lazy(() => import('src/pages/dashboard/report/details'));
const ModulesetupCreatePage = lazy(() => import('src/pages/dashboard/report/new'));
const VatListPage = lazy(() => import('src/pages/dashboard/report/taxList'))
const ReportEditPage = lazy(() => import('src/pages/dashboard/report/edit'));
const ReportNewListNewReport = lazy(() => import('src/pages/dashboard/report/newlist'));
const ModuleTaxsetupCreatePage = lazy(() => import('src/pages/dashboard/report/newTax'));
// CHART OF ACCOUNTS
const ChartofAccountsListPage = lazy(() => import('src/pages/dashboard/chartOfAccounts/list'));
const AccountListPage = lazy(() => import('src/pages/dashboard/chartOfAccounts/accountsList'));
const JournalListPage = lazy(() => import('src/pages/dashboard/chartOfAccounts/listView'));
const GenaralLedgerListPage = lazy(() => import('src/pages/dashboard/genaralLedger/list'));
const TrialBalanceListPage = lazy(() => import('src/pages/dashboard/trialBalance/list'));
const AccountCreatePage = lazy(() => import('src/pages/dashboard/chartOfAccounts/accountsCreate'));
// const ReportDetailsPage = lazy(() => import('src/pages/dashboard/report/details'));
const ChartofAccountCreatePage = lazy(() => import('src/pages/dashboard/chartOfAccounts/new'));
// const ReportEditPage = lazy(() => import('src/pages/dashboard/report/edit'));
// const ReportNewListNewReport = lazy(() => import('src/pages/dashboard/report/newlist'));
// const ModuleTaxsetupCreatePage = lazy(() => import('src/pages/dashboard/report/newTax'));
// ERP_INVOICE
const ERPInvoiceListPage = lazy(() => import('src/pages/dashboard/erpinvoice/list'));
const ERPInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/erpinvoice/details'));
const ERPInvoiceCreatePage = lazy(() => import('src/pages/dashboard/erpinvoice/new'));
const ERPInvoiceEditPage = lazy(() => import('src/pages/dashboard/erpinvoice/edit'));
// PO
const POListPage = lazy(() => import('src/pages/dashboard/po/list'));
const PODetailsPage = lazy(() => import('src/pages/dashboard/po/details'));
const POCreatePage = lazy(() => import('src/pages/dashboard/po/new'));
const POEditPage = lazy(() => import('src/pages/dashboard/po/edit'));
// MEASUREMENT
const MeasurementListPage = lazy(() => import('src/pages/dashboard/measurement/list'));
const MeasurementDetailsPage = lazy(() => import('src/pages/dashboard/measurement/details'));
const MeasurementCreatePage = lazy(() => import('src/pages/dashboard/measurement/new'));
const MeasurementEditPage = lazy(() => import('src/pages/dashboard/measurement/edit'));
// TRIAL CONFIRMNATION
const TrialConfirmationListPage = lazy(() => import('src/pages/dashboard/trialConfirmation/list'));
const TrialConfirmedListPage = lazy(() => import('src/pages/dashboard/trialConfirmation/confirmedlist'));
const TrialConfirmationDetailsPage = lazy(() => import('src/pages/dashboard/trialConfirmation/details'));
const TrialConfirmationNewEditPage = lazy(() => import('src/pages/dashboard/trialConfirmation/edit'));
const TrialConfirmedDetailsPage = lazy(() => import('src/pages/dashboard/trialConfirmation/confirmedDetails'));
// STOCKISSUING
const StockIssuingListPage = lazy(() => import('src/pages/dashboard/stockIssuing/list'));
const StockIssuedListPage = lazy(() => import('src/pages/dashboard/stockIssuing/Issuedlist'));
const StockIssuedDetailsPage = lazy(() => import('src/pages/dashboard/stockIssuing/stockIssuedDetails'));
const StockIssuringDetailsPage = lazy(() => import('src/pages/dashboard/stockIssuing/details'));
const StockIssuingCreatePage = lazy(() => import('src/pages/dashboard/stockIssuing/new'));
// const MeasurementEditPage = lazy(() => import('src/pages/dashboard/measurement/edit'));
// MEASUREMENT
const MeasurementViewListPage = lazy(() => import('src/pages/dashboard/measurementView/list'))
const MeasurementDetailsViewPage = lazy(() => import('src/pages/dashboard/measurementView/details'))
// GRN
const GRNListPage = lazy(() => import('src/pages/dashboard/grn/list'));
const GRNDetailsPage = lazy(() => import('src/pages/dashboard/grn/details'));
const GRNCreatePage = lazy(() => import('src/pages/dashboard/grn/new'));
const GRNEditPage = lazy(() => import('src/pages/dashboard/grn/edit'));
const DirectGRNCreateNewPage = lazy(() => import('src/pages/dashboard/grn/directGrn'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// VENDORS
const VendorProfilePage = lazy(() => import('src/pages/dashboard/vendor/profile'));
const VendorCardsPage = lazy(() => import('src/pages/dashboard/vendor/cards'));
const VendorListPage = lazy(() => import('src/pages/dashboard/vendor/list'));
const VendorAccountPage = lazy(() => import('src/pages/dashboard/vendor/account'));
const VendorCreatePage = lazy(() => import('src/pages/dashboard/vendor/new'));
const VendorEditPage = lazy(() => import('src/pages/dashboard/vendor/edit'));
// SYSTEM
const SystemCreatePage = lazy(() => import('src/pages/dashboard/system/new'));
const SystemListPage = lazy(() => import('src/pages/dashboard/system/list'));
const SystemCardsPage = lazy(() => import('src/pages/dashboard/system/cards'));
const SystemEditPage = lazy(() => import('src/pages/dashboard/system/edit'));
const SystemPoPage = lazy(() => import('src/pages/dashboard/system/po'));
const SystemGrnPage = lazy(() => import('src/pages/dashboard/system/grn'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// System Master Data
const SystemMasterListPage = lazy(() => import('src/pages/dashboard/systemData/list'));
const SystemMasterDetailsPage = lazy(() => import('src/pages/dashboard/systemData/details'));
const SystemMasterCreatePage = lazy(() => import('src/pages/dashboard/systemData/new'));
const SystemMasterEditPage = lazy(() => import('src/pages/dashboard/systemData/edit'));
const SystemMasterDataMasterDataCreatePage = lazy(() =>
  import('src/pages/dashboard/systemData/masterData')
);
const SystemMasterReferenceCreatePage = lazy(() => import('src/pages/dashboard/systemData/reference'));
const FiscalYearCreatePage = lazy(() => import('src/pages/dashboard/systemData/fiscalYear'));

// Measurement Master
const DesignNumberCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createDesignNumber'));
const RelationCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createRelation'));
const FitCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createFit'));
const SSEAMCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createSSEAM'));
const FUNDALengthCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createFUNDALength'));
const NoOfPKTCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createBUTNType'));
const LOOPCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createLOOP'));
const BackCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createBack'));
const NeckStyleCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createNeckStyle'));
const HalfCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createHalf'));
const StyleCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createStyle'));
const ShCutCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createShCut'));
const PocketTypeCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createPocketType'));
const FundaCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createFunda'));
const DFTypeCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createDFType '));
const SHTypeCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createSHType'));
const KALLYCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createKALLY'));
const DarizFrontCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createDarizFront '));
const DarizNeckCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createDarizNeck'));
const DarizSleeveCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createDarizSleeve'));
const DarizTypeCreatePage = lazy(() => import('src/pages/dashboard/measurementMaster/createDarizType'));

// Admin User
const AdminUserProfilePage = lazy(() => import('src/pages/dashboard/adminUser/profile'));
const AdminUserCardsPage = lazy(() => import('src/pages/dashboard/adminUser/cards'));
const AdminUserListPage = lazy(() => import('src/pages/dashboard/adminUser/list'));
const AdminUserCreatePage = lazy(() => import('src/pages/dashboard/adminUser/new'));
const AdminUserEditPage = lazy(() => import('src/pages/dashboard/adminUser/edit'));

// System Data - uom
const UomCreatePage = lazy(() => import('src/pages/dashboard/systemData/uom'));
const PersonalDefinitionCreatePage = lazy(() => import('src/pages/dashboard/systemData/personalDefinition'));
const CreatesubAccountTypePage = lazy(() => import('src/pages/dashboard/systemData/subAccountType'));
const CreatesubAccPage = lazy(() => import('src/pages/dashboard/systemData/subAcc'));
const CostCenterCreateViewPage = lazy(() => import('src/pages/dashboard/systemData/costCenter'));
const FloatCreatePage = lazy(() => import('src/pages/dashboard/systemData/float'));


const NewAdminUserCreatePage = lazy(() => import('src/pages/dashboard/systemData/adminUser'));
// ERP POS
const SampleDashboardOverview = lazy(() => import('src/pages/dashboard/erpPos/erp_po'));
const POSSecondview = lazy(() => import('src/pages/dashboard/erpPos/pos_secondview'));
const POSThiredview = lazy(() => import('src/pages/dashboard/erpPos/pos_thiredview'));
const POSFourthView = lazy(() => import('src/pages/dashboard/erpPos/pos_fourthview'));
const PurchaseHistoryList = lazy(() => import('src/pages/dashboard/erpPos/pos_list'));
const PurchaseSecondViewHistoryList = lazy(() => import('src/pages/dashboard/erpPos/pos_secondView_list'));
const PurchaseSecondViewDiscountList = lazy(() => import('src/pages/dashboard/erpPos/pos_secondView_discount'));
const ReturnBillHistoryList = lazy(() => import('src/pages/dashboard/erpPos/pos_returnBill'));
const ERPNewUser = lazy(() => import('src/pages/dashboard/erpPos/erp_newUser'));
// CASH COLLECTION
const CashCollectionReport = lazy(() => import('src/pages/dashboard/cashCollectionReport/cashCollectionReport'))
const PosInvoiceDetailsViewList = lazy(() => import('src/pages/dashboard/erpPos/pos_details'))
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

const PosCheckInCheckoutViewPage = lazy(() => import('src/pages/dashboard/posCheckInCheckout'));

// Item Master
const MasterDataListView = lazy(() => import('src/sections/itemMaster/masterData-list-view'));
const MasterDataListViewTwo = lazy(() => import('src/sections/itemMaster/masterData-list-viewTwo'));
const MaterialMasterCreateView = lazy(() =>
  import('src/sections/itemMaster/materialM-create-view')
);
const MaterialNewEditForm = lazy(() => import('src/sections/itemMaster/material-new-edit-form'));
const ItemEditView = lazy(() => import('src/sections/itemMaster/item-edit-view'));
const ItemEditViewTwo = lazy(() => import('src/sections/itemMaster/item-edit-viewTwo'));
const MasterDataProfileView = lazy(() => import('src/sections/itemMaster/itemMaster-view'));
const MasterDataProfileViewTwo = lazy(() => import('src/sections/itemMaster/itemMaster-viewTwo'));
// SALES ORDER
const SalesOrderListPage = lazy(() => import('src/pages/dashboard/salesOrders/list'));
const SalesOrderDetailsPage = lazy(() => import('src/pages/dashboard/salesOrders/details'));
const SalesOrderCreatePage = lazy(() => import('src/pages/dashboard/salesOrders/new'));
const SalesOrderEditPage = lazy(() => import('src/pages/dashboard/salesOrders/edit'));
// PETTY PAYMENTS
const CashPaymentListView = lazy(() => import('src/pages/dashboard/pettyCash/list'));
const PettyReimbursement = lazy(() => import('src/pages/dashboard/pettyCash/reimbursement'));
// const SalesOrderDetailsPage = lazy(() => import('src/pages/dashboard/salesOrders/details'));
const CashPaymentCreatePage = lazy(() => import('src/pages/dashboard/pettyCash/new'));
const PaymentDetailsPage = lazy(() => import('src/pages/dashboard/pettyCash/details'));
// CASH & BANK PAYMENTS
const CashBankPaymentListView = lazy(() => import('src/pages/dashboard/bank&cash/list'));
// const SalesOrderDetailsPage = lazy(() => import('src/pages/dashboard/salesOrders/details'));
const CashBankPaymentCreatePage = lazy(() => import('src/pages/dashboard/bank&cash/new'));
const CashBankDetailsPage = lazy(() => import('src/pages/dashboard/bank&cash/details'));
// const SalesOrderEditPage = lazy(() => import('src/pages/dashboard/salesOrders/edit'));
// CASH RECEIPT
const CashBankReceiptsCreatePage = lazy(() => import('src/pages/dashboard/receipts/new'))
const CashReceiptsNewListViewPage = lazy(() => import('src/pages/dashboard/receipts/list'))
const CashReceiptDetailsPage = lazy(() => import('src/pages/dashboard/receipts/details'))
// CASH RECEIPT
const BankReceiptsCreatePage = lazy(() => import('src/pages/dashboard/bankReceipts/new'))
const BankReceiptsNewListViewPage = lazy(() => import('src/pages/dashboard/bankReceipts/list'))
const BankReceiptDetailsPage = lazy(() => import('src/pages/dashboard/bankReceipts/details'))
// PERIOD DEFINITION
const PeriodDefinitionCreatePage = lazy(() => import('src/pages/dashboard/periodDefinition/new'))
const PeriodDefinitionEditPage = lazy(() => import('src/pages/dashboard/periodDefinition/edit'))
// CASH AND BANK
const CashandBankReceiptsCreatePage = lazy(() => import('src/pages/dashboard/cash&bankReceipt/new'))
const CashandBankReceiptCommonDetailsPage = lazy(() => import('src/pages/dashboard/cash&bankReceipt/details'))
const BankReceiptsCommonListViewPage = lazy(() => import('src/pages/dashboard/cash&bankReceipt/list'))

// BILL
const BillNewListView = lazy(() => import('src/pages/dashboard/bill/list'));
// const SalesOrderDetailsPage = lazy(() => import('src/pages/dashboard/salesOrders/details'));
const BillCreatePage = lazy(() => import('src/pages/dashboard/bill/new'));
// CASHSALE
const CashSaleNewListView = lazy(() => import('src/pages/dashboard/cashSale/list'));
// const SalesOrderDetailsPage = lazy(() => import('src/pages/dashboard/salesOrders/details'));
const CashSaleCreatePage = lazy(() => import('src/pages/dashboard/cashSale/new'));
// const SalesOrderEditPage = lazy(() => import('src/pages/dashboard/salesOrders/edit'));
// CREDITSALE
const CreditSaleNewListView = lazy(() => import('src/pages/dashboard/creditSale/list'));
// const SalesOrderDetailsPage = lazy(() => import('src/pages/dashboard/salesOrders/details'));
const CreditSaleCreatePage = lazy(() => import('src/pages/dashboard/creditSale/new'));
// const SalesOrderEditPage = lazy(() => import('src/pages/dashboard/salesOrders/edit'));
// OUT BOUND DELIVERY
const OutBoundDelivery = lazy(() => import('src/pages/dashboard/outBoundDelivery/list'));
const OutBoundDeliveryApprovalPage = lazy(() =>
  import('src/pages/dashboard/outBoundDelivery/details')
);
const OutBoundDeliveryListView = lazy(() =>
  import('src/pages/dashboard/outBoundDelivery/outBoundlist')
);
// POST DATED CHEQUE DETAILS
const PastDatedChequeListPage = lazy(() =>
  import('src/pages/dashboard/postDatedChequeDetails/list')
);
// PRICE MANAGEMENT
const PriceManagementCreatePage = lazy(() =>
  import('src/pages/dashboard/priceManagement/new')
);
const PriceManagementList = lazy(() =>
  import('src/pages/dashboard/priceManagement/list')
);
// CURRENCY MANAGEMENT
const CurrencyManagementCreatePage = lazy(() =>
  import('src/pages/dashboard/currencyManagement/new')
);
const CurrencyManagementList = lazy(() =>
  import('src/pages/dashboard/currencyManagement/list')
);
// CURRENCY MANAGEMENT CONFIG
const CurrencyManagementConfigurePage = lazy(() =>
  import('src/pages/dashboard/currencyManagement/configure')
);

const CurrencyManagemntEditPage = lazy(() => import('src/pages/dashboard/currencyManagement/edit'));

const PostDatedChequeDetailsViewPage = lazy(() =>
  import('src/pages/dashboard/postDatedChequeDetails/details')
);
// CASH RECEIVABLE
const CashReceivableDetailsViewPage = lazy(() =>
  import('src/pages/dashboard/cashReceivable/details')
);
// RECEIVABLE
const CashReceivableListPage = lazy(() => import('src/pages/dashboard/cashReceivable/list'));
const CashReceivedList = lazy(() => import('src/pages/dashboard/cashReceivable/receivedList'));
const CashReceivedDetailsViewPage = lazy(() => import('src/pages/dashboard/cashReceivable/receivedDetails'));

// CASH PAYABLE
const CashPayableDetailsViewPage = lazy(() => import('src/pages/dashboard/cashPayable/details'));
const CashPayableListPage = lazy(() => import('src/pages/dashboard/cashPayable/list'));

// POST DATED PAYBLE CHEQUE DETAILS
const PastDatedPaybleChequeListPage = lazy(() =>
  import('src/pages/dashboard/pDatedPaybleChequeDetails/list')
);
const PostDatedPaybleChequeDetailsViewPage = lazy(() =>
  import('src/pages/dashboard/pDatedPaybleChequeDetails/details')
);

// PICKING LIST
const PickingList = lazy(() => import('src/pages/dashboard/pickingList/list'));
const PickingListDeliveryPlanPage = lazy(() => import('src/pages/dashboard/pickingList/details'));
const AllPickingListDataView = lazy(() => import('src/pages/dashboard/pickingList/allPickingList'));
// DELIVERY LIST
const DeliveryListPage = lazy(() => import('src/pages/dashboard/delivery/list'));
const DeliveryDetailsPage = lazy(() => import('src/pages/dashboard/delivery/details'));
const SalesOrderInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/delivery/soInvoice'));
// OUT GOING PAYMENT
const OutGoingPayment = lazy(() => import('src/pages/dashboard/outGoingPayment/list'));
// IN COMING PAYMENT
const InComingPayment = lazy(() => import('src/pages/dashboard/inComingPayment/list'));
// ACCOUNT RECEIVABLE
const AccountReceivableListPage = lazy(() => import('src/pages/dashboard/accountReceivable/list'));
const AccountReceivablePaymentPage = lazy(() =>
  import('src/pages/dashboard/accountReceivable/details')
);
// STOCK TRANSFER ACCEPT
const StockTransferAcceptListPage = lazy(() => import('src/pages/dashboard/stockTransferAccept/list'));
const StockTransferDetailViewPage = lazy(() => import('src/pages/dashboard/stockTransferAccept/details'));

// POS RECEIVABLE
const PosReceivableListPage = lazy(() => import('src/pages/dashboard/posReceivable/list'));
const PosReceivablePaymentPage = lazy(() =>
  import('src/pages/dashboard/posReceivable/details')
);
// ACCOUNT PAYBLE
const AccountPaybleListPage = lazy(() => import('src/pages/dashboard/accountPayble/list'));
const AccountPayblePaymentPage = lazy(() => import('src/pages/dashboard/accountPayble/details'));
// INTERNAL TRANSFER
const InternalTransferCreatePage = lazy(() => import('src/pages/dashboard/internalTransfer/new'));
const InternalTransferDetailsViewPage = lazy(() =>
  import('src/pages/dashboard/internalTransfer/details')
);
// STOCK ADJUSTMENT
const StockAdjustmentNewCreatePage = lazy(() => import('src/pages/dashboard/stockAdjustment/new'));
const StockAdjustmentDetailsViewPage = lazy(() => import('src/pages/dashboard/stockAdjustment/details'));

const AdditionalMaterialNewCreatePage = lazy(() => import('src/pages/dashboard/additionalMaterial/new'));
// const AdditionalMaterialListPage = lazy(() =>import('src/pages/dashboard/additionalMaterial/list'));
// PAYMENT
const PaymentPage = lazy(() => import('src/pages/dashboard/payment/new'));
const PaymentListPage = lazy(() => import('src/pages/dashboard/payment/list'));
// MANUFACTURING
const ManufacturingCreatePage = lazy(() => import('src/pages/dashboard/manufacturing/new'))
const ManufacturingMasterDataCreatePage = lazy(() => import('src/pages/dashboard/manufacturing/masterData'))

// CREDIT MANAGEMENT
const CreditManagementListPage = lazy(() => import('src/pages/dashboard/creditMan/list'));
const CreditManagementDetailsPage = lazy(() => import('src/pages/dashboard/creditMan/details'));
const CreditManagementCreatePage = lazy(() => import('src/pages/dashboard/creditMan/new'));
const CreditManagementEditPage = lazy(() => import('src/pages/dashboard/creditMan/edit'));
// STOCK MOVEMENT
const StockMovement = lazy(() => import('src/pages/dashboard/stockMovement/list'));
const StockMovementDetailsPage = lazy(() => import('src/pages/dashboard/stockMovement/details'));
const StockMovementLinePage = lazy(() => import('src/pages/dashboard/stockMovement/stockLine'));
// STOCK MOVEMENT
const StockReportListPage = lazy(() => import('src/pages/dashboard/stockReport/list'));
// PRICE
const PriceListPage = lazy(() => import('src/pages/dashboard/price/list'));
const PriceDetailsPage = lazy(() => import('src/pages/dashboard/price/details'));
const PriceCreatePage = lazy(() => import('src/pages/dashboard/price/new'));
const PriceEditPage = lazy(() => import('src/pages/dashboard/price/edit'));
const PriceAssignPage = lazy(() => import('src/pages/dashboard/price/assignPrice'));
const PriceAssignListPage = lazy(() => import('src/pages/dashboard/price/assignPriceList'));
// FINANCE CONFIG
const FinConfigCreatePage = lazy(() => import('src/pages/dashboard/finConfig/new'))
const FinConfigEditPage = lazy(() => import('src/pages/dashboard/finConfig/edit'))
const AccLine = lazy(() => import('src/pages/dashboard/finConfig/accLine'));
const FinConfigSetPandLPage = lazy(() => import('src/pages/dashboard/finConfig/setPandL'))
const FinConfigAccGroupPage = lazy(() => import('src/pages/dashboard/finConfig/configAccGroup'))
const FinConfigAccGroupEditPage = lazy(() => import('src/pages/dashboard/finConfig/configAccGroupEdit'))
const AccGroupLineEditPage = lazy(() => import('src/pages/dashboard/finConfig/AccGroupLineEdit'))
const AccGroupOparationPage = lazy(() => import('src/pages/dashboard/finConfig/accOparation'))
// Corporate Discount
const CorporateDiscountCreatePage = lazy(() => import('src/pages/dashboard/corporateDiscount/new'))
const DefineCorporateDiscountPage = lazy(() => import('src/pages/dashboard/corporateDiscount/corporateDiscount'))
const DefineCorporateDiscountLinePage = lazy(() => import('src/pages/dashboard/corporateDiscount/corporateDiscountLine'))
const DefineCorporateDiscountEditPage = lazy(() => import('src/pages/dashboard/corporateDiscount/corporateDis-edit'))

// PRODUCT CETEGORY
// const PriceListPage = lazy(() => import('src/pages/dashboard/price/list'));
// const PriceDetailsPage = lazy(() => import('src/pages/dashboard/price/details'));
const ProductCategoryCreateViewPage = lazy(() => import('src/pages/dashboard/productCategory/new'));
// const PriceEditPage = lazy(() => import('src/pages/dashboard/price/edit'));
// const PriceAssignPage = lazy(() => import('src/pages/dashboard/price/assignPrice'));
// 
const CostUpdateCreatePage = lazy(() => import('src/pages/dashboard/costUpdate/new'));
const CostUpdateConfigPage = lazy(() => import('src/pages/dashboard/costUpdate/costConfig'));
const CostUpdateListPage = lazy(() => import('src/pages/dashboard/costUpdate/list'));
const CostUpdateEditPage = lazy(() => import('src/pages/dashboard/costUpdate/edit'));
const CostUpdateViewPage = lazy(() => import('src/pages/dashboard/costUpdate/view'));
const CostUpdateFinalListView = lazy(() => import('src/pages/dashboard/costUpdate/finalList'));
const CostUpdatePendingListPage = lazy(() => import('src/pages/dashboard/costUpdate/pendingList'));
const FinalCostUpdateCreatePage = lazy(() => import('src/pages/dashboard/costUpdate/finalCostUpdate'));
// REPORT
const CreateJournalEntryPage = lazy(() => import('src/pages/dashboard/journalEntry/create-Journal-Entry'));

const SalesGroup = lazy(() => import('src/pages/dashboard/report/salesGroup'));
const CustomerwiseReport = lazy(() => import('src/pages/dashboard/report/customerWise'));
const ItemwiseSalesReport = lazy(() => import('src/pages/dashboard/report/itemWise'));
const CollectionReport = lazy(() => import('src/pages/dashboard/report/collection'));
const SalesSummaryReport = lazy(() => import('src/pages/dashboard/report/salesSummary'));
const StockMomentReport = lazy(() => import('src/pages/dashboard/report/stockMomentReport'));
const StockDetailsReport = lazy(() => import('src/pages/dashboard/report/stockDetailsReport'));
const LocationwiseStockReport = lazy(() => import('src/pages/dashboard/report/locationWise'));
const ItemGroupwiseStockReport = lazy(() => import('src/pages/dashboard/report/groupWise'));
const DetailsStockReport = lazy(() => import('src/pages/dashboard/report/detailsStock'));
const LocationwiseDetailsStockReport = lazy(() => import('src/pages/dashboard/report/locationWiseDetails'));
const LocationwiseDetailsSalesSummaryReport = lazy(() => import('src/pages/dashboard/report/locationWiseDetailsSalesSummary'));
const LocationwiseDetailsStockSummaryReport = lazy(() => import('src/pages/dashboard/report/locationWiseDetailsStockSummary'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'pos', element: <OverviewPOSView /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'vendor',
        children: [
          { element: <VendorProfilePage />, index: true },
          { path: ':id/profile', element: <VendorProfilePage /> },
          { path: 'cards', element: <VendorCardsPage /> },
          { path: 'list', element: <VendorListPage /> },
          { path: 'new', element: <VendorCreatePage /> },
          { path: ':id/edit', element: <VendorEditPage /> },
          { path: 'account', element: <VendorAccountPage /> },
        ],
      },
      {
        path: 'system',
        children: [
          { element: <SystemProfileView />, index: true },
          { path: 'profile', element: <SystemProfileView /> },
          { path: 'cards', element: <SystemCardsPage /> },
          { path: 'list', element: <SystemListPage /> },
          { path: 'new', element: <SystemCreatePage /> },
          { path: ':id/edit', element: <SystemEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
          { path: 'purchaseorder', element: <SystemPoPage /> },
          { path: 'grn', element: <SystemGrnPage /> },
        ],
      },

      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'multipleStockTransfer',
        children: [
          { element: <MultipleStockTransferListPage />, index: true },
          { path: 'list', element: <MultipleStockTransferListPage /> },
          { path: ':id', element: <MultipleStockTransferDetailsPage /> },
          { path: ':id/edit', element: <MultipleStockTransferEditPage /> },
          { path: 'new', element: <MultipleStockTransferCreatePage /> },
        ],
      },
      {
        path: 'multipleStockTransferAccept',
        children: [

          { path: ':id', element: <MultipleStockTransferAcceptPage /> },

        ],
      },
      {
        path: 'module',
        children: [
          { path: 'new', element: <ModulesetupCreatePage /> },
          { path: 'taxList', element: <VatListPage /> },
          { path: ':id/edit', element: <ReportEditPage /> },
        ],
      },
      {
        path: 'report',
        children: [
          // { element: <BalanceListPage />, index: true },
          { path: 'balancesheet', element: <BalanceListPage /> },
          { path: 'list', element: <SalesGroup /> },
          { path: 'customerwise', element: <CustomerwiseReport /> },
          { path: 'itemwise', element: <ItemwiseSalesReport /> },
          { path: 'collection', element: <CollectionReport /> },
          { path: 'salesSummary', element: <SalesSummaryReport /> },
          { path: 'locationwisestockReport', element: <LocationwiseStockReport /> },
          { path: 'itemGroupwisestockReport', element: <ItemGroupwiseStockReport /> },
          { path: 'stockMomentReport', element: <StockMomentReport /> },
          { path: 'stockDetailsReport', element: <StockDetailsReport /> },
          { path: 'detailsStockReport', element: <DetailsStockReport /> },
          { path: 'locationdetailsStockReport', element: <LocationwiseDetailsStockReport /> },
          { path: 'locationdetailsSalesSummary', element: <LocationwiseDetailsSalesSummaryReport /> },
          { path: 'locationdetailsStockSummary', element: <LocationwiseDetailsStockSummaryReport /> },
          { path: 'newlist', element: <ReportNewListNewReport /> },
          { path: ':id', element: <ReportDetailsPage /> },
          { path: ':id/edit', element: <ReportEditPage /> },
          { path: 'new', element: <ModulesetupCreatePage /> },
          { path: 'newtax', element: <ModuleTaxsetupCreatePage /> },
        ],
      },
      {
        path: 'pnl',
        children: [
          { element: <ProfitandLosstListPage />, index: true },
          { path: 'list', element: <ProfitandLosstListPage /> },
          { path: 'new', element: <ProfitandLosstCreatePage /> },
        ],
      },
      {
        path: 'chartofaccounts',
        children: [
          { element: <ChartofAccountsListPage />, index: true },
          { path: 'list', element: <ChartofAccountsListPage /> },
          { path: 'accountlist', element: <AccountListPage /> },
          { path: ':id', element: <JournalListPage /> },
          { path: 'genaralledger', element: <GenaralLedgerListPage /> },
          { path: 'trialbalance', element: <TrialBalanceListPage /> },
          // { path: 'newlist', element: <ReportNewListNewReport /> },
          // { path: ':id', element: <ReportDetailsPage /> },
          // { path: ':id/edit', element: <ReportEditPage /> },
          { path: 'new', element: <ChartofAccountCreatePage /> },
          { path: 'createnew', element: <AccountCreatePage /> },
          // { path: 'newtax', element: <ModuleTaxsetupCreatePage /> },
        ],
      },
      {
        path: 'erpinvoice',
        children: [
          { element: <ERPInvoiceListPage />, index: true },
          { path: 'list', element: <ERPInvoiceListPage /> },
          { path: ':id', element: <ERPInvoiceDetailsPage /> },
          { path: ':id/edit', element: <ERPInvoiceEditPage /> },
          { path: 'new', element: <ERPInvoiceCreatePage /> },
        ],
      },
      {
        path: 'po',
        children: [
          { element: <POListPage />, index: true },
          { path: 'list', element: <POListPage /> },
          { path: ':id', element: <PODetailsPage /> },
          { path: ':id/edit', element: <POEditPage /> },
          { path: 'new', element: <POCreatePage /> },
        ],
      },
      {
        path: 'measurement',
        children: [
          { element: <MeasurementListPage />, index: true },
          { path: 'list', element: <MeasurementListPage /> },
          { path: ':id', element: <MeasurementDetailsPage /> },
          { path: ':id/edit', element: <MeasurementEditPage /> },
          { path: 'new', element: <MeasurementCreatePage /> },
        ],
      },
      {
        path: 'stockissuing',
        children: [
          { element: <StockIssuingListPage />, index: true },
          { path: 'list', element: <StockIssuingListPage /> },
          { path: 'issuedList', element: <StockIssuedListPage /> },
          { path: ':id', element: <StockIssuringDetailsPage /> },
          { path: ':id', element: <StockIssuedDetailsPage /> },
          { path: ':id/edit', element: <MeasurementEditPage /> },
          { path: 'new', element: <StockIssuingCreatePage /> },
        ],
      },
      {
        path: 'stockissued',
        children: [
          { path: ':id', element: <StockIssuedDetailsPage /> },
        ],
      },
      {
        path: 'measurementview',
        children: [
          { element: <MeasurementViewListPage />, index: true },
          { path: 'list', element: <MeasurementViewListPage /> },
          { path: ':id', element: <MeasurementDetailsViewPage /> },
          // { path: ':id/edit', element: <MeasurementEditPage /> },
          // { path: 'new', element: <MeasurementCreatePage /> },
        ],
      },
      {
        path: 'salesOrder',
        children: [
          { element: <SalesOrderListPage />, index: true },
          { path: 'list', element: <SalesOrderListPage /> },
          { path: ':id', element: <SalesOrderDetailsPage /> },
          { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'cashandbank',
        children: [
          { element: <CashPaymentListView />, index: true },
          { path: 'list', element: <CashPaymentListView /> },
          { path: 'reimbursement', element: <PettyReimbursement /> },
          // { path: ':id', element: <SalesOrderDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <CashPaymentCreatePage /> },
          { path: ':id', element: <PaymentDetailsPage /> },
        ],
      },
      {
        path: 'cashandbankpayment',
        children: [
          { element: <CashBankPaymentListView />, index: true },
          { path: 'list', element: <CashBankPaymentListView /> },
          // { path: ':id', element: <SalesOrderDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <CashBankPaymentCreatePage /> },
          { path: ':id', element: <CashBankDetailsPage /> },
        ],
      },
      {
        path: 'receipts',
        children: [
          { element: <CashReceiptsNewListViewPage />, index: true },
          { path: 'list', element: <CashReceiptsNewListViewPage /> },
          { path: ':id', element: <CashReceiptDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <CashBankReceiptsCreatePage /> },
          // { path: ':id', element: <CashBankDetailsPage /> },
        ],
      },
      {
        path: 'bankReceipts',
        children: [
          { element: <BankReceiptsNewListViewPage />, index: true },
          { path: 'list', element: <BankReceiptsNewListViewPage /> },
          { path: ':id', element: <BankReceiptDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <BankReceiptsCreatePage /> },
          // { path: ':id', element: <CashBankDetailsPage /> },
        ],
      },
      {
        path: 'periodDefinition',
        children: [
          // { element: <BankReceiptsNewListViewPage />, index: true },
          { path: 'new', element: <PeriodDefinitionCreatePage /> },
          { path: ':id/edit', element: <PeriodDefinitionEditPage /> },
        ],
      },
      {
        path: 'cashandbankReceipts',
        children: [
          { element: <BankReceiptsCommonListViewPage />, index: true },
          { path: 'list', element: <BankReceiptsCommonListViewPage /> },
          { path: ':id', element: <CashandBankReceiptCommonDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <CashandBankReceiptsCreatePage /> },
          // { path: ':id', element: <CashBankDetailsPage /> },
        ],
      },
      {
        path: 'bill',
        children: [
          { element: <BillNewListView />, index: true },
          { path: 'list', element: <BillNewListView /> },
          // { path: ':id', element: <SalesOrderDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <BillCreatePage /> },
        ],
      },
      {
        path: 'cashSale',
        children: [
          { element: <CashSaleNewListView />, index: true },
          { path: 'list', element: <CashSaleNewListView /> },
          // { path: ':id', element: <SalesOrderDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <CashSaleCreatePage /> },
        ],
      },
      {
        path: 'costUpdate',
        children: [
          { element: <CostUpdateListPage />, index: true },
          { path: ':id/edit', element: <CostUpdateEditPage /> },
          { path: ':id/view', element: <CostUpdateViewPage /> },
          // { path: 'list', element: <CashSaleNewListView /> },
          // { path: ':id', element: <SalesOrderDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <CostUpdateCreatePage /> },
          { path: 'config', element: <CostUpdateConfigPage /> },
          { path: 'final', element: <CostUpdateFinalListView /> },
          { path: 'pending', element: <CostUpdatePendingListPage /> },
          { path: 'finalCostUpdate', element: <FinalCostUpdateCreatePage /> },

        ],
      },
      {

        path: 'journalEntry',
        children: [
          { path: 'new', element: <CreateJournalEntryPage /> },
        ],
      },
      {
        path: 'creditSale',
        children: [
          { element: <CreditSaleNewListView />, index: true },
          { path: 'list', element: <CreditSaleNewListView /> },
          // { path: ':id', element: <SalesOrderDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <CreditSaleCreatePage /> },
        ],
      },
      {
        path: 'outBoundDelivery',
        children: [
          { element: <OutBoundDelivery />, index: true },
          { path: 'list', element: <OutBoundDelivery /> },
          { path: ':id', element: <OutBoundDeliveryApprovalPage /> },
          { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'accountReceivable',
        children: [
          { element: <AccountReceivableListPage />, index: true },
          { path: 'list', element: <AccountReceivableListPage /> },
          { path: ':id', element: <AccountReceivablePaymentPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'stockTransferAccept',
        children: [
          { element: <StockTransferAcceptListPage />, index: true },
          { path: 'list', element: <StockTransferAcceptListPage /> },
          { path: ':id', element: <StockTransferDetailViewPage /> },
          // { path: ':id', element: <AccountReceivablePaymentPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'posReceivable',
        children: [
          { element: <PosReceivableListPage />, index: true },
          { path: 'list', element: <PosReceivableListPage /> },
          { path: ':id', element: <PosReceivablePaymentPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'accountPayble',
        children: [
          { element: <AccountPaybleListPage />, index: true },
          { path: 'list', element: <AccountPaybleListPage /> },
          { path: ':id', element: <AccountPayblePaymentPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'postDatedChequeDetails',
        children: [
          { element: <PastDatedChequeListPage />, index: true },
          { path: 'list', element: <PastDatedChequeListPage /> },
          { path: ':id', element: <PostDatedChequeDetailsViewPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'cashReceivable',
        children: [
          { element: <CashReceivableListPage />, index: true },
          { path: 'list', element: <CashReceivableListPage /> },
          { path: 'receivedList', element: <CashReceivedList /> },
          { path: ':id', element: <CashReceivableDetailsViewPage /> },
          { path: ':id', element: <CashReceivedDetailsViewPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'cashReceived',
        children: [
          { path: ':id', element: <CashReceivedDetailsViewPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'cashPayble',
        children: [
          { element: <CashPayableListPage />, index: true },
          { path: 'list', element: <CashPayableListPage /> },
          { path: ':id', element: <CashPayableDetailsViewPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'postDatedChequeDetails',
        children: [
          { element: <PastDatedChequeListPage />, index: true },
          { path: 'list', element: <PastDatedChequeListPage /> },
          { path: ':id', element: <PostDatedChequeDetailsViewPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'postDatedPayble',
        children: [
          { element: <PastDatedPaybleChequeListPage />, index: true },
          { path: 'list', element: <PastDatedPaybleChequeListPage /> },
          { path: ':id', element: <PostDatedPaybleChequeDetailsViewPage /> },
          // { path: 'outBoundlist', element: <OutBoundDeliveryListView /> },

          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'pickingList',
        children: [
          { element: <PickingList />, index: true },
          { path: 'list', element: <PickingList /> },
          { path: ':id', element: <PickingListDeliveryPlanPage /> },
          { path: 'allpicking', element: <AllPickingListDataView /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'deliveryIn',
        children: [{ path: ':id', element: <SalesOrderInvoiceDetailsPage /> }],
      },
      {
        path: 'delivery',
        children: [
          { element: <DeliveryListPage />, index: true },
          { path: 'list', element: <DeliveryListPage /> },
          { path: ':id', element: <DeliveryDetailsPage /> },
          // { path: ':id', element: <SalesOrderInvoiceDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'outGoingpayment',
        children: [
          { element: <OutGoingPayment />, index: true },
          { path: 'list', element: <OutGoingPayment /> },
          // { path: ':id', element: <SalesOrderDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'inComingpayment',
        children: [
          { element: <InComingPayment />, index: true },
          { path: 'list', element: <InComingPayment /> },
          // { path: ':id', element: <SalesOrderDetailsPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'stockMovement',
        children: [
          { element: <StockMovement />, index: true },
          { path: 'list', element: <StockMovement /> },
          { path: ':id', element: <StockMovementDetailsPage /> },
          { path: ':id', element: <StockMovementLinePage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'stockReport',
        children: [
          { element: <StockReportListPage />, index: true },
          { path: 'list', element: <StockReportListPage /> },
          // { path: ':id', element: <StockMovementDetailsPage /> },
          // { path: ':id', element: <StockMovementLinePage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          // { path: 'new', element: <SalesOrderCreatePage /> },
        ],
      },
      {
        path: 'stockLine',
        children: [{ path: ':id/:Batchid', element: <StockMovementLinePage /> }],
      },
      {
        path: 'grn',
        children: [
          { element: <GRNListPage />, index: true },
          { path: 'list', element: <GRNListPage /> },
          { path: ':id', element: <GRNDetailsPage /> },
          { path: ':id/edit', element: <GRNEditPage /> },
          { path: 'new', element: <GRNCreatePage /> },
          { path: 'directgrn', element: <DirectGRNCreateNewPage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      {
        path: 'materialmaster',
        children: [
          { element: <MasterDataListView />, index: true },
          { path: 'list', element: <MasterDataListView /> },
          { path: 'newlist', element: <MasterDataListViewTwo /> },
          { path: 'new', element: <MaterialMasterCreateView /> },
          { path: ':id/edit', element: <ItemEditView /> },
          { path: ':id/newEdit', element: <ItemEditViewTwo /> },
          { path: ':id/view', element: <MasterDataProfileView /> },
          { path: ':id/newView', element: <MasterDataProfileViewTwo /> },
        ],
      },
      {
        path: 'systemMasterData',
        children: [
          { element: <SystemMasterListPage />, index: true },
          { path: 'list', element: <SystemMasterListPage /> },
          { path: ':id', element: <SystemMasterDetailsPage /> },
          { path: ':id/edit', element: <SystemMasterEditPage /> },
          { path: 'new', element: <SystemMasterCreatePage /> },
          // { path: 'masterDataMaster', element: <MasterDataCreatePage /> },
          { path: 'masterData', element: <SystemMasterDataMasterDataCreatePage /> },
          { path: 'reference', element: <SystemMasterReferenceCreatePage /> },
          { path: 'adminUser', element: <NewAdminUserCreatePage /> },
          { path: 'uom', element: <UomCreatePage /> },
          { path: 'personalDefinition', element: <PersonalDefinitionCreatePage /> },
          { path: 'subAccounts', element: <CreatesubAccountTypePage /> },
          { path: 'subAcc', element: <CreatesubAccPage /> },
          { path: 'costCenter', element: <CostCenterCreateViewPage /> },
          { path: 'float', element: <FloatCreatePage /> },
          { path: 'fiscalYear', element: <FiscalYearCreatePage /> },
        ],
      },
      {
        path: 'measurementMaster',
        children: [
          { path: 'designNumber', element: <DesignNumberCreatePage />, index: true },
          { path: 'relation', element: <RelationCreatePage />, index: true },
          { path: 'fit', element: <FitCreatePage />, index: true },
          { path: 'sSeam', element: <SSEAMCreatePage />, index: true },
          { path: 'fundaLength', element: <FUNDALengthCreatePage />, index: true },
          { path: 'noOfPkt', element: <NoOfPKTCreatePage />, index: true },
          { path: 'loop', element: <LOOPCreatePage />, index: true },
          { path: 'back', element: <BackCreatePage />, index: true },
          { path: 'neckStyle', element: <NeckStyleCreatePage />, index: true },
          { path: 'half', element: <HalfCreatePage />, index: true },
          { path: 'style', element: <StyleCreatePage />, index: true },
          { path: 'shCut', element: <ShCutCreatePage />, index: true },
          { path: 'pocketType', element: <PocketTypeCreatePage />, index: true },
          { path: 'funda', element: <FundaCreatePage />, index: true },
          { path: 'dfType', element: <DFTypeCreatePage />, index: true },
          { path: 'shType', element: <SHTypeCreatePage />, index: true },
          { path: 'kally', element: <KALLYCreatePage />, index: true },
          { path: 'front', element: <DarizFrontCreatePage />, index: true },
          { path: 'neck', element: <DarizNeckCreatePage />, index: true },
          { path: 'sleeve', element: <DarizSleeveCreatePage />, index: true },
          { path: 'darizType', element: <DarizTypeCreatePage />, index: true },
        ],
      },

      {
        path: 'posmainview',
        children: [
          { element: <SampleDashboardOverview />, index: true },
          { path: 'purchaseHistory', element: <PurchaseHistoryList /> },
          { path: 'returnBillHistory', element: <ReturnBillHistoryList /> },
          { path: 'erpUser', element: <ERPNewUser /> },
          { path: 'secondview', element: <POSSecondview /> },

        ],
      },
      {
        path: 'possecondview',
        children: [
          { element: <POSSecondview />, index: true },
          { path: 'purchaseHistory', element: <PurchaseSecondViewHistoryList /> },
          { path: 'purchaseDiscountHistory', element: <PurchaseSecondViewDiscountList /> },
          { path: ':id', element: <PosInvoiceDetailsViewList /> },
        ],
      },
      {
        path: 'cashCollectionreport',
        children: [
          { element: <CashCollectionReport />, index: true },
          { path: 'cashCollectionreportList', element: <CashCollectionReport /> },
          // { path: ':id', element: <PosInvoiceDetailsViewList /> },
        ],
      },
      {
        path: 'posthirdview',
        children: [{ element: <POSThiredview />, index: true }],
      },
      {
        path: 'posfourthview',
        children: [{ element: <POSFourthView />, index: true }],
      },

      {
        path: 'creditMan',
        children: [
          { element: <CreditManagementListPage />, index: true },
          { path: 'list', element: <CreditManagementListPage /> },
          { path: ':id', element: <CreditManagementDetailsPage /> },
          { path: ':id/edit', element: <CreditManagementEditPage /> },
          { path: 'new', element: <CreditManagementCreatePage /> },
        ],
      },
      {
        path: 'price',
        children: [
          { element: <PriceListPage />, index: true },
          { path: 'list', element: <PriceListPage /> },
          { path: ':id', element: <PriceDetailsPage /> },
          { path: ':id/edit', element: <PriceEditPage /> },
          { path: 'new', element: <PriceCreatePage /> },
          { path: 'assignPrice', element: <PriceAssignPage /> },
          { path: 'assignPriceList', element: <PriceAssignListPage /> },
        ],
      },
      {
        path: 'category',
        children: [
          // { element: <PriceListPage />, index: true },
          // { path: 'list', element: <PriceListPage /> },
          // { path: ':id', element: <PriceDetailsPage /> },
          // { path: ':id/edit', element: <PriceEditPage /> },
          { path: 'new', element: <ProductCategoryCreateViewPage /> },
          // { path: 'assignPrice', element: <PriceAssignPage /> },
        ],
      },
      {
        path: 'manufacturing',
        children: [
          { path: 'new', element: <ManufacturingCreatePage /> },
          { path: 'masterData', element: <ManufacturingMasterDataCreatePage /> },
        ],
      },
      {
        path: 'adminUser',
        children: [
          { element: <AdminUserProfilePage />, index: true },
          { path: ':id/profile', element: <AdminUserProfilePage /> },
          { path: 'cards', element: <AdminUserCardsPage /> },
          { path: 'list', element: <AdminUserListPage /> },
          { path: 'new', element: <AdminUserCreatePage /> },
          { path: ':id/edit', element: <AdminUserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'internalTransfer',
        children: [
          // { element: <SalesOrderListPage />, index: true },
          // { path: 'list', element: <SalesOrderListPage /> },
          { path: ':id', element: <InternalTransferDetailsViewPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <InternalTransferCreatePage /> },
        ],
      },
      {
        path: 'priceManagement',
        children: [
          { path: 'new', element: <PriceManagementCreatePage /> },
          { path: 'list', element: <PriceManagementList /> },
        ],
      },
      {
        path: 'currencyManagement',
        children: [
          { path: 'new', element: <CurrencyManagementCreatePage /> },
          { path: 'list', element: <CurrencyManagementList /> },
          { path: 'configure', element: <CurrencyManagementConfigurePage /> },
          { path: ':id/edit', element: <CurrencyManagemntEditPage /> },
        ],
      },
      {
        path: 'finConfig',
        children: [
          { path: 'new', element: <FinConfigCreatePage /> },
          { path: 'setConfig', element: <FinConfigSetPandLPage /> },
          { path: 'accGroupConfig', element: <FinConfigAccGroupPage /> },
          { path: 'accLine', element: <AccLine /> },
          { path: 'accOparation', element: <AccGroupOparationPage /> },
          // { path: 'configure', element: <CurrencyManagementConfigurePage /> },
          { path: ':id/edit', element: <FinConfigEditPage /> },
          { path: ':id/groupEdit', element: <FinConfigAccGroupEditPage /> },
          { path: ':id/lineEdit', element: <AccGroupLineEditPage /> },
        ],
      },
      {
        path: 'corporateDiscount',
        children: [
          { path: 'new', element: <CorporateDiscountCreatePage /> },
          { path: 'defineDiscount', element: <DefineCorporateDiscountPage /> },
          { path: 'defineDiscountline', element: <DefineCorporateDiscountLinePage /> },
          { path: ':id/define', element: <DefineCorporateDiscountEditPage /> },

        ],
      },
      {
        path: 'stockAdjustment',
        children: [
          // { element: <SalesOrderListPage />, index: true },
          // { path: 'list', element: <SalesOrderListPage /> },
          { path: ':id', element: <StockAdjustmentDetailsViewPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <StockAdjustmentNewCreatePage /> },
          { path: 'Additional_material', element: <AdditionalMaterialNewCreatePage /> },


        ],
      },
      // {
      //   path: 'additionalMaterial',
      //   children: [
      //     // { element: <AdditionalMaterialListPage />, index: true },
      //     { path: 'list', element: <AdditionalMaterialListPage /> },
      //     // { path: ':id', element: <StockAdjustmentDetailsViewPage /> },
      //     // { path: ':id/edit', element: <SalesOrderEditPage /> },



      //   ],
      // },
      {
        path: 'payment',
        children: [
          // { element: <SalesOrderListPage />, index: true },
          { path: 'list', element: <PaymentListPage /> },
          // { path: ':id', element: <InternalTransferDetailsViewPage /> },
          // { path: ':id/edit', element: <SalesOrderEditPage /> },
          { path: 'new', element: <PaymentPage /> },
        ],
      },
      // {
      //   path: 'report',
      //   children: [
      //     // { element: <SalesOrderListPage />, index: true },
      //     { path: 'list', element: <SalesReport /> },
      //     // { path: ':id', element: <InternalTransferDetailsViewPage /> },
      //     // { path: ':id/edit', element: <SalesOrderEditPage /> },
      //     // { path: 'new', element: <PaymentPage /> },
      //   ],
      // },
      {
        path: 'trialConfirmation',
        children: [
          { element: <TrialConfirmationListPage />, index: true },
          { path: 'list', element: <TrialConfirmationListPage /> },
          { path: 'confirmedlist', element: <TrialConfirmedListPage /> },
          { path: ':id', element: <TrialConfirmationDetailsPage /> },
          { path: ':id/edit/:lineid', element: <TrialConfirmationNewEditPage /> },
          { path: ':id/confirmed', element: <TrialConfirmedDetailsPage /> },
        ],
      },

      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
      { path: 'posCheckingCheckout', element: <PosCheckInCheckoutViewPage /> },
    ],
  },
];
