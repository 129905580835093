// import PropTypes from 'prop-types';
// import { useState, useCallback, useEffect } from 'react';
// // @mui
// import { useTheme } from '@mui/material/styles';
// import Stack from '@mui/material/Stack';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import { Box } from '@mui/material';
// import Chip from '@mui/material/Chip';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// // theme
// import { bgBlur } from 'src/theme/css';
// // hooks
// import { useOffSetTop } from 'src/hooks/use-off-set-top';
// import { useResponsive } from 'src/hooks/use-responsive';
// import { useBoolean } from 'src/hooks/use-boolean';
// // routes
// import { paths } from 'src/routes/paths';
// import { useRouter } from 'src/routes/hook';
// // redux
// import { useSelector } from 'src/redux/store';
// import { useDispatch } from 'react-redux';
// import {
//   loginSuccess,
//   loginFailure,
//   clearLoginSuccess,
//   updateLocation,
//   updateLocationName,
//   updateCheckinCheckout,
//   updateNewStatus,
// } from 'src/redux/slices/authAction';
// // axios
// import { endpoints, getFetcher, getFetcher1, getFetcherPrams, postPramsFetcher } from 'src/utils/axios';

// import { useSnackbar } from 'src/components/snackbar';

// // components
// import Logo from 'src/components/logo';
// import SvgColor from 'src/components/svg-color';
// import { useSettingsContext } from 'src/components/settings';
// import UserLocationSelect from 'src/sections/auth/jwt/user-location-select';
// //
// import { HEADER, NAV } from '../config-layout';
// import {
//   Searchbar,
//   AccountPopover,
//   SettingsButton,
//   LanguagePopover,
//   ContactsPopover,
//   NotificationsPopover,
// } from '../_common';
// import CashRegisterReport from './CashRegisterReport';


// // ----------------------------------------------------------------------

// export default function Header({ onOpenNav }) {
//   const userLocationdetails = useSelector((state) => state.auth.selected_location_name);
//   console.log('userLocationdetails', userLocationdetails);
//   const theme = useTheme();
//   const dispatch = useDispatch();
//   const { enqueueSnackbar } = useSnackbar();
//   const reduxData = useSelector((state) => state.auth);
//   console.log('reduxDataSumedha', reduxData);
//   const settings = useSettingsContext();

//   const [locationSelectOpen, setLocationSelectOpen] = useState(false);

//   const router = useRouter();

//   const [loading, setLoading] = useState(true);

//   const loadingSend = useBoolean();

//   const loginLocation = useSelector((state) => state.auth.selected_location);

//   const isNavHorizontal = settings.themeLayout === 'horizontal';

//   const isNavMini = settings.themeLayout === 'mini';

//   const lgUp = useResponsive('up', 'lg');

//   const offset = useOffSetTop(HEADER.H_DESKTOP);

//   const offsetTop = offset && !isNavHorizontal;

//   const Checkout = async () => {
//     try {
//       const response = await getFetcher1(endpoints.pos.checkInCheckOut);

//       if (response.status === 200) {
//         enqueueSnackbar('Check out recorded !', {
//           autoHideDuration: 3000,
//           anchorOrigin: { vertical: 'top', horizontal: 'center' },
//           sx: {
//             width: '400px',
//           },
//         });
//         dispatch(updateCheckinCheckout(false));
//         // dispatch(updateNewStatus(false));

//       } else {
//         enqueueSnackbar('Check out failed !', {
//           autoHideDuration: 3000,
//           anchorOrigin: { vertical: 'top', horizontal: 'center' },
//           sx: {
//             width: '400px',
//           },
//         });
//       }
//     } catch (error) {
//       enqueueSnackbar('Check out failed !', {
//         autoHideDuration: 3000,
//         anchorOrigin: { vertical: 'top', horizontal: 'center' },
//         sx: {
//           width: '400px',
//         },
//       });
//     }
//   }


//   const CashRegisterOpenAndClose = async () => {
//     loadingSend.onTrue();

//     setLocationSelectOpen(true);

//     const requestParams = {
//       branch_id: loginLocation,
//     };

//     try {
//       await Checkout();
//       // await new Promise((resolve) => setTimeout(resolve, 500));

//       const config = {
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//       };

//       const response = await postPramsFetcher(
//         endpoints.cashRegisterOpenAndClose.getRegisterOpen,
//         null,
//         requestParams,
//         config
//       );

//       console.log('responseLakmal', response);

//       enqueueSnackbar('Cash Register Close Success!!!', {
//         autoHideDuration: 3000,
//         anchorOrigin: { vertical: 'top', horizontal: 'center' },
//         sx: {
//           width: '400px',
//         },
//       });
//       // dispatch(updateCheckinCheckout(false));

//       // router.push(paths.dashboard.posCheckingCheckout);


//       window.location.reload()
//       // setLoading(true);


//       loadingSend.onFalse();
//     } catch (error) {
//       enqueueSnackbar('Cash Register Close Failed!!!', {
//         autoHideDuration: 3000,
//         anchorOrigin: { vertical: 'top', horizontal: 'center' },
//         sx: {
//           width: '400px',
//         },
//       });

//       loadingSend.onFalse();
//     }
//     setLoading(false);
//   };

//   const renderContent = (
//     <>
//       {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

//       {!lgUp && (
//         <IconButton onClick={onOpenNav}>
//           <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
//         </IconButton>
//       )}

//       <Searchbar />

//       <Stack
//         flexGrow={1}
//         direction="row"
//         alignItems="center"
//         justifyContent="flex-end"
//         spacing={{ xs: 0.5, sm: 1 }}
//       >

//         {/* {reduxData?.checkinStatus === true(<Box mr={1}>
//           <Chip icon={<RemoveShoppingCartIcon />} label='Check out' variant="outlined" color="error" onClick={Checkout} />
//         </Box>)} */}

//         {/* {reduxData && reduxData?.newStatus?.closed === false && ( */}
//         {reduxData?.checkInCheckoutStatus?.cashRegistyOpen === true && (
//           <Box mr={1}>
//             <Chip
//               icon={<AccountBalanceWalletIcon />}
//               // label={`Cash Register Open for ${reduxData?.newStatus?.check_in_date}`}
//               label='Close cash registry'
//               variant="outlined"
//               color="warning"
//               onClick={CashRegisterOpenAndClose}
//             />
//             <Chip sx={{ ml: 2 }}
//               icon={<AccountBalanceWalletIcon />}
//               // label={`Cash Register Open for ${reduxData?.newStatus?.check_in_date}`}
//               label={reduxData?.checkInCheckoutStatus?.checkinStatus}
//               variant="outlined"
//               color="success"
//             />
//           </Box>
//         )}
//         {reduxData && reduxData.checkinStatus === true && (
//           <Box mr={1}>
//             <Chip
//               icon={<RemoveShoppingCartIcon />}
//               label='Check out'
//               variant="outlined"
//               color="error"
//               onClick={Checkout}
//             />
//           </Box>
//         )}


//         <Box mr={2}>
//           <Chip icon={<LocationOnIcon />} label={userLocationdetails} variant="outlined" color="success" />
//         </Box>

//         <LanguagePopover />

//         {/* <NotificationsPopover /> */}

//         {/* <ContactsPopover /> */}

//         <SettingsButton />

//         <AccountPopover />
//       </Stack>
//     </>
//   );

//   return (
//     <AppBar
//       sx={{
//         height: HEADER.H_MOBILE,
//         zIndex: theme.zIndex.appBar + 1,
//         ...bgBlur({
//           color: theme.palette.background.default,
//         }),
//         transition: theme.transitions.create(['height'], {
//           duration: theme.transitions.duration.shorter,
//         }),
//         ...(lgUp && {
//           width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
//           height: HEADER.H_DESKTOP,
//           ...(offsetTop && {
//             height: HEADER.H_DESKTOP_OFFSET,
//           }),
//           ...(isNavHorizontal && {
//             width: 1,
//             bgcolor: 'background.default',
//             height: HEADER.H_DESKTOP_OFFSET,
//             borderBottom: `dashed 1px ${theme.palette.divider}`,
//           }),
//           ...(isNavMini && {
//             width: `calc(100% - ${NAV.W_MINI + 1}px)`,
//           }),
//         }),
//       }}
//     >

//       <CashRegisterReport
//         // loginData={loginData}
//         // userDetails={userCredentials}
//         open={locationSelectOpen}
//         onClose={() => setLocationSelectOpen(false)}
//       // onLocationSelect={handleLocationSelect}
//       />

//       <Toolbar
//         sx={{
//           height: 1,
//           px: { lg: 5 },
//         }}
//       >
//         {renderContent}
//       </Toolbar>
//     </AppBar>
//   );
// }

// Header.propTypes = {
//   onOpenNav: PropTypes.func,
// };


import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// redux
import { useSelector } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import {
  loginSuccess,
  loginFailure,
  clearLoginSuccess,
  updateLocation,
  updateLocationName,
  updateCheckinCheckout,
  updateNewStatus,
} from 'src/redux/slices/authAction';
// axios
import { endpoints, getFetcher, getFetcher1, getFetcherPrams, postPramsFetcher } from 'src/utils/axios';

import { useSnackbar } from 'src/components/snackbar';

// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import UserLocationSelect from 'src/sections/auth/jwt/user-location-select';
import CashRegisterReport from 'src/sections/cashCollectionReport/CashRegisterReport';
//
import { formatDate } from 'src/utils/format-number';

import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const userLocationdetails = useSelector((state) => state.auth.selected_location_name);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const reduxData = useSelector((state) => state.auth);
  console.log('reduxDataSumedha', reduxData);
  const settings = useSettingsContext();

  const [locationSelectOpen, setLocationSelectOpen] = useState(false);
  const [confirmCloseCashRegister, setConfirmCloseCashRegister] = useState(false);

  const router = useRouter();

  const [loading, setLoading] = useState(true);

  const loadingSend = useBoolean();

  const loginLocation = useSelector((state) => state.auth.selected_location);

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const Checkout = async () => {
    try {
      const response = await getFetcher1(endpoints.pos.checkInCheckOut);

      if (response.status === 200) {
        enqueueSnackbar('Check out recorded !', {
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          sx: {
            width: '400px',
          },
        });
        dispatch(updateCheckinCheckout(false));
        // dispatch(updateNewStatus(false));

      } else {
        enqueueSnackbar('Check out failed !', {
          autoHideDuration: 3000,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          sx: {
            width: '400px',
          },
        });
      }
    } catch (error) {
      enqueueSnackbar('Check out failed !', {
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        sx: {
          width: '400px',
        },
      });
    }
  }


  const CashRegisterOpenAndClose = async () => {
    loadingSend.onTrue();

    setLocationSelectOpen(true);

    const requestParams = {
      branch_id: loginLocation,
    };

    try {
      await Checkout();
      // await new Promise((resolve) => setTimeout(resolve, 500));

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const response = await postPramsFetcher(
        endpoints.cashRegisterOpenAndClose.getRegisterOpen,
        null,
        requestParams,
        config
      );

      console.log('responseLakmal', response);

      enqueueSnackbar('Cash Register Close Success!!!', {
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        sx: {
          width: '400px',
        },
      });
      // dispatch(updateCheckinCheckout(false));

      // router.push(paths.dashboard.posCheckingCheckout);
      window.location.reload()
      // setLoading(true);
      loadingSend.onFalse();
    } catch (error) {
      enqueueSnackbar('Cash Register Close Failed!!!', {
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        sx: {
          width: '400px',
        },
      });

      loadingSend.onFalse();
    }
    setLoading(false);
  };

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Searchbar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >

        {/* {reduxData?.checkinStatus === true(<Box mr={1}>
          <Chip icon={<RemoveShoppingCartIcon />} label='Check out' variant="outlined" color="error" onClick={Checkout} />
        </Box>)} */}

        {/* {reduxData && reduxData?.newStatus?.closed === false && ( */}
        {reduxData?.checkInCheckoutStatus?.cashRegistyOpen === true && (
          <Box mr={1}>
            <Chip
              icon={<AccountBalanceWalletIcon />}
              // label={`Cash Register Open for ${reduxData?.newStatus?.check_in_date}`}
              label='Close cash registry'
              variant="outlined"
              color="warning"
              onClick={() => setConfirmCloseCashRegister(true)}
            />
            <Chip sx={{ ml: 2 }}
              icon={<AccountBalanceWalletIcon />}
              // label={`Cash Register Open for ${reduxData?.newStatus?.check_in_date}`}
              label={formatDate(reduxData?.checkInCheckoutStatus?.checkinStatus)}
              variant="outlined"
              color="success"
            />
          </Box>
        )}
        {reduxData && reduxData.checkinStatus === true && (
          <Box mr={1}>
            <Chip
              icon={<RemoveShoppingCartIcon />}
              label='Check out'
              variant="outlined"
              color="error"
              onClick={Checkout}
            />
          </Box>
        )}


        <Box mr={2}>
          <Chip icon={<LocationOnIcon />} label={userLocationdetails} variant="outlined" color="success" />
        </Box>

        <LanguagePopover />

        {/* <NotificationsPopover /> */}

        {/* <ContactsPopover /> */}

        <SettingsButton />

        <AccountPopover />
      </Stack>
    </>
  );
  console.log("dateMy1",formatDate(reduxData?.checkInCheckoutStatus?.checkinStatus));

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >

      <CashRegisterReport
        // loginData={loginData}
        // userDetails={userCredentials}
        open={locationSelectOpen || confirmCloseCashRegister}
        onClose={() => {
          setLocationSelectOpen(false);
          setConfirmCloseCashRegister(false);
        }}
        onConfirmCloseCashRegister={CashRegisterOpenAndClose}
        cashRegisterCloseDate = {formatDate(reduxData?.checkInCheckoutStatus?.checkinStatus)}// oshada
      />

      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
