import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';

// ----------------------------------------------------------------------

export default function RHFTextField({ name, helperText, type, ...other }) {
  const { control, setValue } = useFormContext();

  const validatePositiveNumber = (value) => {
    const numericValue = Number(value);
    if (Number.isNaN(numericValue) || numericValue <= 0) {
      return 'Please enter a positive number';
    }
    return true;
  };

  const validatePositiveDecimal = (value) => {
    // const decimalRegex = /^\[0-9]*[.]*[0-9]*$/; // Regular expression to allow decimal numbers
    // if (!decimalRegex.test(value) || value.includes('-')) {
    //   return false;
    // }
    // return true;
  };

  const getValidationRule = () => {
    if (type === 'number') {
      return validatePositiveNumber;
    }
    if (type === 'decimal') {
      return validatePositiveDecimal;
    }
    return undefined;
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: getValidationRule() }}
      render={({ field, fieldState: { error } }) => (
        <TextField
        {...field}
       
        fullWidth
        type={type === 'decimal' ? "number" : type}
        inputProps={{
       
          step: type === 'decimal' ? 0.5 : '1',
          pattern: type === 'decimal' ? '[0-9]*[.]*[0-9]*' : undefined, // Use pattern attribute for HTML5 validation
        }}
    
        value={field.value}
       
        
        error={!!error}
        helperText={error ? error?.message : helperText}
          {...other}
      />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.oneOf(['number', 'decimal']), // Accepts 'number' or 'decimal' types
};

// import PropTypes from 'prop-types';
// import { useFormContext, Controller } from 'react-hook-form';
// import TextField from '@mui/material/TextField';

// // ----------------------------------------------------------------------

// export default function RHFTextField({ name, helperText, type, ...other }) {
//   const { control } = useFormContext();

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field, fieldState: { error } }) => (
//         <TextField
//           {...field}
//           fullWidth
//           type={type}
//           value={field.value || ''}
//           onChange={field.onChange}
//           error={!!error}
//           helperText={error ? error.message : helperText}
//           {...other}
//         />
//       )}
//     />
//   );
// }

// RHFTextField.propTypes = {
//   helperText: PropTypes.string,
//   name: PropTypes.string,
//   type: PropTypes.string,
// };
